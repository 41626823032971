import { PlacementsSection } from 'components/bricks/types/placement.type';
import Translation from 'components/data/Translation';

const placements: PlacementsSection[] = [
    {
        key: 'pinterest-link-ad',
        label: Translation.get('adSetup.placements.title', 'bricks'),
        children: [
            {
                recommendedHeight: 1,
                recommendedWidth: 1,
                minWidth: 250,
                type: 'link-ad',
                key: 'pinterest-link-ad',
                label: 'Pinterest link ad',
                platform: 'pinterest',
                channel: 'social',
                frameWidth: 265,
                multiFrame: false
            }
        ]
    }
];

export default placements;
