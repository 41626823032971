import React, { useMemo } from 'react';
import AdSetupInputWrapper from 'components/bricks/components/shared/components/ad-setup/components/ad-setup-input-wrapper';
import Accordion from 'components/bricks/components/shared/components/accordion';
import CreativeSelector from 'components/input/CreativeSelector';
import { FieldSetCopy } from 'components/input/Copy';
import Translation from 'components/data/Translation';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import { withResources } from 'components/data/Resources';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import Divider from 'components/ui-components-v2/Divider';
import brickSetup from './setup';

interface Props {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;
}

const SingleFrame: React.FC<Props> = ({ handleSelectCreatives }) => {
    const { adSetup, updateAdSetupProperty, creatives, selectedFrame } = useAdSetupContext();
    const pinterestAdSetup: any = adSetup; // TODO Temporary because the adsetup is typed for meta and not in general

    const inputValidators = useMemo(() => {
        return {
            message: ValidateHelpers.getInputValidators('pinterest_ad', `pinterest_ad-ad_setup-message`),
            title: ValidateHelpers.getInputValidators('pinterest_ad', `pinterest_ad-ad_setup-title-${selectedFrame}`),
            description: ValidateHelpers.getInputValidators('pinterest_ad', `pinterest_ad-ad_setup-description-${selectedFrame}`),
            website_url: ValidateHelpers.getInputValidators('pinterest_ad', `pinterest_ad-ad_setup-website_url-${selectedFrame}`)
        };
    }, [selectedFrame]);

    const { multiple, canEditCreative, selectors, templateTypes = [] } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <Accordion square title={Translation.get('adSetup.main', 'bricks')}>
            <div className="ad-setup__accordion__inputs">
                <CreativeSelector
                    onMutation={(creatives) => handleSelectCreatives(creatives)}
                    multiple={multiple}
                    canEditCreative={canEditCreative}
                    value={creatives}
                    selectors={selectors}
                    templateTypes={templateTypes}
                />

                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.xAd.message', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('message', newValue)}
                        value={pinterestAdSetup.message}
                        validators={inputValidators.message}
                    />
                </AdSetupInputWrapper>

                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.xAd.description', 'bricks')}
                        onMutation={(newValue) => updateAdSetupProperty('description', newValue, true)}
                        value={pinterestAdSetup?.items?.[0].description}
                        useValueObject={false}
                        validators={inputValidators.description}
                    />
                </AdSetupInputWrapper>

                <Divider />
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.xAd.websiteUrl', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('website_url', newValue, true)}
                        value={pinterestAdSetup?.items?.[0].website_url}
                        validators={inputValidators.website_url}
                    />
                </AdSetupInputWrapper>
            </div>
        </Accordion>
    );
};

export default withResources(SingleFrame, ['facebook_cta']);
