import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'pinterest_adgroup',
    title: 'Pinterest ad group',
    channel: 'social',
    platform: 'pinterest',
    defaultTab: 'settings',
    description: 'Create an ad group for a Pinterest campaign',
    addBrickText: 'Add ad group',
    outputAction: 'publish',
    availableParentBricks: ['pinterest_campaign'],
    availableChildBricks: ['pinterest_ad'],
    additionalVars: ['pinterest_campaign'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ],
    config: {
        general: {
            publish: {
                previewType: 'settings'
            }
        }
    }
};

export default setup;
