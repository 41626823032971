import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'pinterest_campaign',
    title: 'Pinterest campaign',
    channel: 'social',
    platform: 'pinterest',
    description: 'Create a Pinterest campaign',
    defaultTab: 'settings',
    addBrickText: 'Add campaign',
    availableParentBricks: [],
    outputAction: 'publish',
    availableChildBricks: ['pinterest_adgroup'],
    additionalVars: ['feed_custom'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output',
            rights: ['brickManagement', 'brickPublish']
        }
    ],
    config: {
        general: {
            publish: {
                previewType: 'settings'
            }
        }
    }
};

export default setup;
