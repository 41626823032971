import briefing from 'components/bricks/components/shared/components/briefing-content/components';
import adSetup from 'components/bricks/components/shared/components/ad-setup';
import output from 'components/bricks/components/shared/components/output-tab';
import settings from './settings';
import setup from './setup';
import icon from './icon.svg';
import defaultData from './default-data';
import adSetupSingleFrame from './ad-setup-single-frame';
import validators from './validators';
import placements from '../data/placements';

export default {
    settings,
    setup,
    output,
    defaultData,
    icon,
    adSetup,
    briefing,
    adSetupSingleFrame,
    validators,
    placements
};
